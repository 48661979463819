import React from 'react'
import TextForm from '../components/TextForm'

export default function Home(props) {
  document.title = props.title;
  return (
    <>      
      <TextForm mode={props.mode} heading="Enter Text:" showAlert={props.showAlert}/>                
    </>    
  )
}
