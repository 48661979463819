import React, { useState } from "react";

export default function TextForm(props) {
    const handleOnChange = (event) => {
        setText(event.target.value);
    }

    const handleSentCaseClick = () => {        
        let tempText = '';
        if (text.charAt(text.length-1) === '.') {
            tempText = text;
        }
        else {
            tempText = text + '.';
        }
        
        let sent = tempText.split('.');        
        let newText = '';
        let s = '';
        for (let i = 0; i < sent.length-1; i++) {
            s = sent[i];
            let newSent = '';
            let ch = '';
            let flag = 0;
            for (let j = 0; j < s.length; j++) {
                if (j === 0) {
                    ch = s[j];
                    if (ch === ' ') {
                        newSent = newSent + ch;
                        if ((s[j+1] >= 'A' && s[j+1] <= 'Z') || (s[j+1] >= 'a' && s[j+1] <= 'z')) {
                            newSent = newSent + s[j+1].toUpperCase();
                            flag = 1;
                            j++;
                        }
                    }
                    else {
                        newSent = newSent + ch.toUpperCase();
                        flag = 1;
                    }
                }
                else {
                    ch = s[j];
                    if (ch === ' ') {
                        newSent = newSent + ch;
                        if (((s[j+1] >= 'A' && s[j+1] <= 'Z') || (s[j+1] >= 'a' && s[j+1] <= 'z')) && flag !== 1){
                            newSent = newSent + s[j+1].toUpperCase();
                            flag = 1;
                            j++;
                        }
                    }
                    else {
                        newSent = newSent + ch;
                    }
                }                
            }

            sent[i] = newSent;
        }

        for (let i = 0; i < sent.length-1; i++) {
            newText = newText + sent[i] + '.';            
        }

        setText(newText);

        props.showAlert("Changed to Sentence case", "success");
    }
    
    const handleUpCaseClick = () => {
        let newText = text.toUpperCase();
        setText(newText);
        
        props.showAlert("Changed to UPPERCASE", "success");
    }

    const handleLoCaseClick = () => {
        let newText = text.toLowerCase();
        setText(newText);
        
        props.showAlert("Changed to lowercase", "success");
    }

    const handleCapEchWrdClick = () => {        
        let newText = '';
        let ch = '';
        for (let i = 0; i < text.length; i++) {
            if (i === 0) {
                ch = text[i];
                if (ch === ' ') {
                    newText = newText + ch;
                    if ((text[i+1] >= 'A' && text[i+1] <= 'Z') || (text[i+1] >= 'a' && text[i+1] <= 'z')) {
                        newText = newText + text[i+1].toUpperCase();
                        i++;
                    }
                }
                else {
                    newText = newText + ch.toUpperCase();
                }
            }
            else {
                ch = text[i];
                if (ch === ' ') {
                    newText = newText + ch;
                    if ((text[i+1] >= 'A' && text[i+1] <= 'Z') || (text[i+1] >= 'a' && text[i+1] <= 'z')) {
                        newText = newText + text[i+1].toUpperCase();
                        i++;
                    }
                }
                else {
                    newText = newText + ch;
                }
            }                        
        }
        setText(newText);
        
        props.showAlert("Each word capitalized", "success");
    }

    const handleTogCaseClick = () => {        
        let newText = '';
        let ch = '';
        for (let i = 0; i < text.length; i++) {
            ch = text[i];
            if (ch >= 'A' && ch <= 'Z') {
                newText = newText + ch.toLowerCase();
            }
            else if (ch >= 'a' && ch <= 'z') {
                newText = newText + ch.toUpperCase();
            } else {
                newText = newText + ch;
            }            
        }
        setText(newText);
        
        props.showAlert("Changed to tOGGLE cASE", "success");
    }

    const handleAlClearClick = () => {        
        let newText = '';
        setText(newText);
        
        props.showAlert("All cleared", "success");
    }
    
    const [text, setText] = useState('');    
    
    //word count
    let wrd = text.split(' ');
    let wrdCount = 0;
    let ch = '';
    for (let i = 0; i < wrd.length; i++) {
        ch = wrd[i];
        if (ch !== '') {
            wrdCount++;
        }        
    }

    return (
        <>    
            <h1>{props.heading}</h1>
            <textarea className="form-control my-3" id="myText" style={{backgroundColor: (props.mode === "light")?"white":"#282c34", color: (props.mode === "light")?"black":"white"}} rows="10" value={text} onChange={handleOnChange}></textarea>
            <button className="btn btn-primary my-3" onClick={handleSentCaseClick}>Sentence case.</button>
            <button className="btn btn-primary my-3 mx-1" onClick={handleUpCaseClick}>UPPERCASE</button>
            <button className="btn btn-primary my-3 mx-1" onClick={handleLoCaseClick}>lowercase</button>
            <button className="btn btn-primary my-3 mx-1" onClick={handleCapEchWrdClick}>Capitalize Each Word</button>
            <button className="btn btn-primary my-3 mx-1" onClick={handleTogCaseClick}>tOOGLE cASE</button>
            <button className="btn btn-danger my-3 mx-1" onClick={handleAlClearClick}>All Clear</button>
            <h3 className="my-3">Text Summary</h3>
            <p><b>No. of words: {wrdCount}<br/>No. of characters: {text.length}</b></p>            
        </>
    )
}
