import React from 'react'
import mylogo from '../images/logo.jpg';

export default function Footer() {
    const mylogostyle = {
        width: "30px",
        height: "30px",
        borderRadius: "50%"
    };

    return (
        <div>
            <p align="center">
                Designed and Developed by <img src={mylogo} alt="mylogo" style={mylogostyle}/>
            </p>
        </div>
    )
}
