// import './App.css';
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Footer from './components/Footer';
import Alert from "./components/Alert";

function App() {
  var menu = ['Home', 'About Us'];

  const [mode, setMode] = useState("light");
  const toggleMode = () => {        
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "#282c34";
      document.body.style.color = "white";
      // showAlert("Dark mode enabled", "success");
    }
    else {
      setMode("light");
      document.body.style.backgroundColor = "white";
      document.body.style.color = "black";
      // showAlert("Light mode enabled", "success");
    }
  }

  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      message : message,
      type : type
    })
    
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }

  return (    
    <Router>      
      <Navbar title="TextUtils" menu={menu} mode={mode} toggleMode={toggleMode}/>
      <Alert alert={alert}/>
      <div className="container my-3">
        <Routes>
          <Route exact path="/" element={<Home title="TextUtils" mode={mode} showAlert={showAlert}/>}/>
          <Route exact path="/aboutus" element={<AboutUs title="TextUtils | About Us"/>}/>          
        </Routes>
      </div>            
      <Footer/>                            
    </Router>         
  );
}

export default App;
