import React from 'react'

export default function Alert(props) {
  const capitalize = (wrd) => {
    const newWrd = wrd.toLowerCase();
    return newWrd.charAt(0).toUpperCase() + newWrd.slice(1);
  }

  return (
    props.alert && <div className="alert alert-primary alert-dismissible fade show" role="alert">
        <strong>{capitalize(props.alert.type)}!</strong> {props.alert.message}        
    </div>
  )
}
