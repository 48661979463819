import React from 'react'

export default function AboutUs(props) {
  document.title = props.title;
  return (
    <>            
      <h1>About Us</h1>
      <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta, enim! Perspiciatis at autem architecto assumenda iusto culpa totam nemo velit nostrum pariatur omnis dignissimos, saepe laudantium, reprehenderit corporis nam. Iste.
      </p>
    </>
  )
}
